import { MDXProvider } from "@mdx-js/react"
import { graphql, Link, StaticQuery } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import * as React from "react"
import { useState } from "react"
import InformationBox from "../components/InformationBox"
// import { MDXRenderer } from "gatsby-plugin-mdx";
import Layout from "../components/Layout"
import TableOfContent from "../components/TableOfContent"
const shortcodes = { InformationBox }
import { Breadcrumb } from "gatsby-plugin-breadcrumb"

const TutorialPageTemplate = ({ data, pageContext }) => {
  const { body, frontmatter } = data.guide
  const [isTocContentExist, setIsTocContentExist] = useState(true)
  const {
    breadcrumb: { crumbs },
  } = pageContext
  crumbs?.map((crumb: any) =>{
    crumb.crumbLabel = crumb?.crumbLabel?.replaceAll("-", " ")
  });
  
  return (
    <Layout>
      <Breadcrumb crumbs={crumbs} crumbSeparator=" > " />
      <div className="d-flex table-of-content-layout">
        <div
          className="body-content"
          style={{ width: !isTocContentExist ? "100%" : "" }}
        >
          <MDXProvider components={shortcodes}>
            <MDXRenderer>{body}</MDXRenderer>
          </MDXProvider>
        </div>
        {isTocContentExist && (
          <div className="toc-content">
            <TableOfContent
              tocName="Table of Content"
              setIsTocContentExist={setIsTocContentExist}
            />
          </div>
        )}
      </div>
      {/* <MDXRenderer>{body}</MDXRenderer> */}
    </Layout>
  )
}
export default TutorialPageTemplate

export const query = graphql`
  query guide($slug: String) {
    guide: mdx(slug: { eq: $slug }) {
      frontmatter {
        title
        date
      }
      slug
      body
      tableOfContents
    }
  }
`
